import React from 'react';
import { NextUIProvider } from '@nextui-org/react';
import {Route, Routes, Navigate} from "react-router-dom";
import {BrowserRouter as Router} from "react-router-dom";
import Home from "./page/home"

function App() {
  return (
    <NextUIProvider>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Navigate replace to="/frontPage" />} /> */}
          <Route path={"/"} element={<Home/>} errorElement={<div>404 not found</div>}>
          {/* <Route path={"/frontPage"} element={<FrontPage/>}/> */}
          </Route>
        </Routes>
      </Router>
    </NextUIProvider>
  );
}

export default App;
