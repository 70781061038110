import { useEffect, useRef, useState } from 'react';

const useWebSocket = () => {
  const ws = useRef(null);
  const heartbeatInterval = useRef(null);
  const [data, setData] = useState([]); 

  useEffect(() => {
    ws.current = new WebSocket('wss://kline.npmcow.com/ws/swap/solana');

    ws.current.onopen = () => {
      console.log('WebSocket 连接已建立');
    };

    ws.current.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);
      console.log('接收到的数据:', receivedData);
      if (receivedData.code === 200 && receivedData.data) {
        const newData = Array.isArray(receivedData.data)
          ? receivedData.data
          : [receivedData.data]; 
  
        setData((prevData) => [...newData, ...prevData]); 
      } else {
        console.error('接收到的数据格式不正确:', receivedData.data);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket 发生错误:', error);
    };

    ws.current.onclose = () => {
      console.log('WebSocket 连接已关闭');
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
      }
    };

    heartbeatInterval.current = setInterval(() => {
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify({ type: 'heartbeat' }));
        console.log('发送心跳请求');
      }
    }, 30000);

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
      }
    };
  }, []);

  return { ws, data };
};

export default useWebSocket;
