import React, { useEffect, useState } from 'react';
import './tukiPage.css';
import { usePairs } from '../api/apix';


const TukiPage = () => {

  const [activeTab, setActiveTab] = useState("5M");
  const { pairs, loading, error } = usePairs();
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (error) {
    return <div>Error: {error}</div>;
  }

  const getColor = (value)=>{
    if (value === undefined || value === 0) {
      return "rgb(164,207,94)"
    }
    if (value > 0) {
      return "rgb(164,207,94)"
    } else {
      return "rgb(244,91,91)"
    }
  }



  const tabs = [
    { label: "5M", value: pairs[0]?.priceChange.m5+"%", color: getColor(pairs[0]?.priceChange.m5)},
    { label: "1H", value: pairs[0]?.priceChange.h1+"%", color: getColor(pairs[0]?.priceChange.h1) },
    { label: "6H", value: pairs[0]?.priceChange.h6+"%", color: getColor(pairs[0]?.priceChange.h6) },
    { label: "24H", value: pairs[0]?.priceChange.h24+"%", color: getColor(pairs[0]?.priceChange.h24) }
  ];

  const data = [{
    TXNS: pairs[0]?.txns.m5.buys+pairs[0]?.txns.m5.sells,
    VOLUME: "$"+Intl.NumberFormat('en-US').format((pairs[0]?.volume.m5/1000).toFixed(1))+"K",
    MAKERS: Intl.NumberFormat('en-US').format(10),
    BUYS: pairs[0]?.txns.m5.buys,
    SELLS: pairs[0]?.txns.m5.sells,
    "BUY VOL": "$3.3K",
    "SELL VOL": "$4.8K",
    BUYERS: 27,
    SELLERS: 18
  },{
    TXNS: pairs[0]?.txns.h1.buys+pairs[0]?.txns.h1.sells,
    VOLUME: "$"+Number((pairs[0]?.volume.h1/1000000).toFixed(1)).toLocaleString()+"M",
    MAKERS: 43,
    BUYS: pairs[0]?.txns.h1.buys,
    SELLS: pairs[0]?.txns.h1.sells,
    "BUY VOL": "$3.3K",
    "SELL VOL": "$4.8K",
    BUYERS: 27,
    SELLERS: 18
  },{
    TXNS: pairs[0]?.txns.h6.buys+pairs[0]?.txns.h6.sells,
    VOLUME: "$"+Number((pairs[0]?.volume.h6/1000000).toFixed(1)).toLocaleString()+"M",
    MAKERS: 43,
    BUYS: pairs[0]?.txns.h6.buys,
    SELLS: pairs[0]?.txns.h6.sells,
    "BUY VOL": "$3.3K",
    "SELL VOL": "$4.8K",
    BUYERS: 27,
    SELLERS: 18
  },{
    TXNS: pairs[0]?.txns.h24.buys+pairs[0]?.txns.h24.sells,
    VOLUME: "$"+Number((pairs[0]?.volume.h24/1000000).toFixed(1)).toLocaleString()+"M",
    MAKERS: 43,
    BUYS: pairs[0]?.txns.h24.buys,
    SELLS: pairs[0]?.txns.h24.sells,
    "BUY VOL": "$3.3K",
    "SELL VOL": "$4.8K",
    BUYERS: 27,
    SELLERS: 18
  },];



  

  

  return (

    


  <div className="tuki-page">
      {/* 顶部栏 */}
      <div className="top-bar">
          <span><img src={pairs[0]?.info.imageUrl} alt="Tuki Logo" className="logo" /></span>
          <span className="title">{pairs[0]?.baseToken.name}</span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span className="menu-icon⋮">⋮</span>
          <span className="menu-icon×">×</span>
          
      </div>

      {/* 主体内容 */}
      <div className="content">
        {/* Tuki / SOL Header */}
        <div className="header">
          <div className="pair">{pairs[0]?.baseToken.symbol} / {pairs[0]?.quoteToken.symbol}</div>
          <div className="info">
            
          {/* <span className='info🔥'>🔥#3</span><span className='info⚡'>⚡1000</span><span className='info🔈'>🔈Ads</span> */}
          </div>
        </div>
        
        <div className="platforms">
          <img className="solana" src="https://dd.dexscreener.com/ds-data/chains/solana.png" loading="lazy"/>
          <span>{pairs[0]?.chainId.charAt(0).toUpperCase() + pairs[0]?.chainId.slice(1)}</span><span> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" focusable="false" class="chakra-icon custom-1jmtuae" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg> </span>          <img className="raydium" src="https://dd.dexscreener.com/ds-data/dexes/raydium.png" loading="lazy"/>
          <span>{pairs[0]?.dexId.charAt(0).toUpperCase() + pairs[0]?.dexId.slice(1)}</span> 
        </div>

        {/* Banner */}
        <div className="banner">
          <img src={pairs[0]?.info.header} alt="Tuki Banner" />
        </div>

        {/* Social Buttons */}
        <div className="social-buttons">
          <div className='button'>
            <a href={pairs[0]?.info.websites[0]?.url} target="_blank" rel="noopener noreferrer"><button className='Website'>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" focusable="false" class="chakra-icon custom-13otjrl" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path></svg>
              Website</button>
            </a>
          </div>
          <div className='button'>
            <a href={pairs[0]?.info.socials[0]?.url} target="_blank" rel="noopener noreferrer"><button className='Twitter'>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" focusable="false" class="chakra-icon custom-13otjrl" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
                </path></svg>
            Twitter</button>
            </a>
          </div>
          <div className='button'>
            <a href={pairs[0]?.info.socials[1]?.url} target="_blank" rel="noopener noreferrer"><button className='Telegram'>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" focusable="false" class="chakra-icon custom-13otjrl" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
              Telegram</button></a>
          </div>
          
          <button className='arrow-down'><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" focusable="false" class="chakra-icon custom-13otjrl" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg></button> 
        </div>
        

        {/* Price and Market Information */}
        <div className="price-info">
          <div className="price-section" style={{marginRight:"6px"}}>
            <div className='price-superscript'>PRICE USD</div>
            <div className="price">${pairs[0]?.priceUsd}</div> 
          </div>
          <div className="price-section">
            <div className='price-superscript'>PRICE</div>
            <div className="price">{pairs[0]?.priceNative} SOL</div>
          </div>
        </div>

        <div className="market-info">
          <div className="market-item"style={{marginRight:"6px"}}>
            <div className='value-superscript'>LIQUIDITY</div>
            <div className="value">${Number((pairs[0]?.liquidity.usd / 1000000).toFixed(0)).toLocaleString()}M</div>
          </div>
          <div className="market-item" style={{marginRight:"6px"}}>
            <div className='value-superscript'>FDV</div>
            <div className="value">${Number((pairs[0]?.fdv/1000000).toFixed(1)).toLocaleString()}M</div>
          </div>
          <div className="market-item">
            <div className='value-superscript'>MKT CAP</div>
            <div className="value">${Number((pairs[0]?.marketCap/1000000).toFixed(1)).toLocaleString()}M</div>
          </div>
        </div>



        {/* Price Change */}


    <div className="dashboard">
      <div className="tabs">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`tab ${activeTab === tab.label ? "active" : ""}`}
            onClick={() => setActiveTab(tab.label)}
          >
            <div style={{fontSize:"12px", paddingTop:"6px", paddingBottom:"4px"}}>{tab.label}</div>
            <span className='tabsSpan' style={{color: tab.color, fontWeight: "bold"}}>{tab.value}</span>
          </div>
        ))}
      </div>
      <div className="statistics">
        {activeTab === '5M' &&
        <>
        <span className='stat'>
        <div className="stat-item">
          <div className='stat-top'>TXNS</div>
          <div className='stat-bottom'>{data[0].TXNS.toLocaleString()}</div>
        </div>
        <div className="stat-item">
          <div className='stat-top'>VOLUME</div>
          <div className='stat-bottom'>{data[0].VOLUME}</div>
        </div>
        <div className="stat-item">
          <div className='stat-top'>MAKERS</div>
          <div className='stat-bottom'>{data[0].MAKERS.toLocaleString()}</div>
        </div>
        </span>
                <span className="bar-container">
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'>BUYS</span>
                      <span className="bar-value">{data[0].BUYS.toLocaleString()}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELLS</span>
                      <span className="bar-value">{data[0].SELLS.toLocaleString()}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: (data[0].BUYS/data[0].TXNS)*100+'%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: (data[0].SELLS/data[0].TXNS)*100+'%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
              
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'> BUY VOL</span>
                      <span className="bar-value">{data[0]["BUY VOL"]}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELL VOL</span>
                      <span className="bar-value">{data[0]["SELL VOL"]}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: '45%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: '55%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
      
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'>BUYERS</span>
                      <span className="bar-value">{data[0].BUYERS}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELLERS</span>
                      <span className="bar-value">{data[0].SELLERS}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: '60%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: '40%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
              </span>
              </>
        }
        {activeTab === '1H' &&
        <>
        <span className='stat'>
        <div className="stat-item">
          <div className='stat-top'>TXNS</div>
          <div className='stat-bottom'>{data[1].TXNS.toLocaleString()}</div>
        </div>
        <div className="stat-item">
          <div className='stat-top'>VOLUME</div>
          <div className='stat-bottom'>{data[1].VOLUME}</div>
        </div>
        <div className="stat-item">
          <div className='stat-top'>MAKERS</div>
          <div className='stat-bottom'>{data[1].MAKERS}</div>
        </div>
        </span>
                <span className="bar-container">
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'>BUYS</span>
                      <span className="bar-value">{data[1].BUYS}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELLS</span>
                      <span className="bar-value">{data[1].SELLS}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: (data[1].BUYS/data[1].TXNS)*100+'%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: (data[1].SELLS/data[1].TXNS)*100+'%', borderRadius:"100000px"}}></div>
                  </div>
                </div>
              
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'> BUY VOL</span>
                      <span className="bar-value">{data[1]["BUY VOL"]}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELL VOL</span>
                      <span className="bar-value">{data[1]["SELL VOL"]}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: '45%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: '55%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
      
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'>BUYERS</span>
                      <span className="bar-value">{data[1].BUYERS}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELLERS</span>
                      <span className="bar-value">{data[1].SELLERS}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: '60%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: '40%', borderRadius:"100000px"}}></div>
                  </div>
                </div>
              </span>
              </>
        }
        {activeTab === '6H' &&
        <>
        <span className='stat'>
        <div className="stat-item">
          <div className='stat-top'>TXNS</div>
          <div className='stat-bottom'>{data[2].TXNS.toLocaleString()}</div>
        </div>
        <div className="stat-item">
          <div className='stat-top'>VOLUME</div>
          <div className='stat-bottom'>{data[2].VOLUME}</div>
        </div>
        <div className="stat-item">
          <div className='stat-top'>MAKERS</div>
          <div className='stat-bottom'>{data[2].MAKERS}</div>
        </div>
        </span>
                <span className="bar-container">
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'>BUYS</span>
                      <span className="bar-value">{data[2].BUYS.toLocaleString()}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELLS</span>
                      <span className="bar-value">{data[2].SELLS.toLocaleString()}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: '60%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: '40%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
              
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'> BUY VOL</span>
                      <span className="bar-value">{data[2]["BUY VOL"]}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELL VOL</span>
                      <span className="bar-value">{data[2]["SELL VOL"]}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: (data[2].BUYS/data[2].TXNS)*100+'%' , borderRadius:"100000px"}}></div>
                    <div className="progress-bar red" style={{ width: (data[2].SELLS/data[2].TXNS)*100+'%' , borderRadius:"100000px"}}></div>
                  </div>
                </div>
      
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'>BUYERS</span>
                      <span className="bar-value">{data[2].BUYERS}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELLERS</span>
                      <span className="bar-value">{data[2].SELLERS}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: '60%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: '40%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
              </span>
              </>
        }
        {activeTab === '24H' &&
        <>
        <span className='stat'>
        <div className="stat-item">
          <div className='stat-top'>TXNS</div>
          <div className='stat-bottom'>{data[3].TXNS.toLocaleString()}</div>
        </div>
        <div className="stat-item">
          <div className='stat-top'>VOLUME</div>
          <div className='stat-bottom'>{data[3].VOLUME}</div>
        </div>
        <div className="stat-item">
          <div className='stat-top'>MAKERS</div>
          <div className='stat-bottom'>{data[3].MAKERS}</div>
        </div>
        </span>
                <span className="bar-container">
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'>BUYS</span>
                      <span className="bar-value">{data[3].BUYS.toLocaleString()}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELLS</span>
                      <span className="bar-value">{data[3].SELLS.toLocaleString()}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: (data[3].BUYS/data[3].TXNS)*100+'%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: (data[3].SELLS/data[3].TXNS)*100+'%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
              
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'> BUY VOL</span>
                      <span className="bar-value">{data[3]["BUY VOL"]}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELL VOL</span>
                      <span className="bar-value">{data[3]["SELL VOL"]}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: '45%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: '55%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
      
                <div className="bar">
                  <div className="label-group">
                    <div className="label">
                      <span className='bar-superscript'>BUYERS</span>
                      <span className="bar-value">{data[3].BUYERS}</span>
                    </div>
                    <div className="label right">
                      <span className='bar-superscript'>SELLERS</span>
                      <span className="bar-value">{data[3].SELLERS}</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div className="progress-bar green" style={{ width: '60%', borderRadius:"100000px" }}></div>
                    <div className="progress-bar red" style={{ width: '40%', borderRadius:"100000px" }}></div>
                  </div>
                </div>
              </span>
              </>
        }
        

            </div>
      </div>
        {/* Watchlist and Alerts */}
        <div className="action-buttons">
          <button className="watchlist">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" focusable="false" class="chakra-icon custom-1gm3tio" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg>
             Watchlist</button>
          <button className="alerts">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" focusable="false" class="chakra-icon custom-13h60r2" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z" ></path></svg>
             
             Alerts</button>
        </div>

        {/* Buy and Sell Buttons */}
        <div className="buy-sell-buttons">
          <button className="buy-button">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" focusable="false" class="chakra-icon custom-1b8pjph" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm143.6 28.9l72.4-75.5V392c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V209.4l72.4 75.5c9.3 9.7 24.8 9.9 34.3.4l10.9-11c9.4-9.4 9.4-24.6 0-33.9L273 107.7c-9.4-9.4-24.6-9.4-33.9 0L106.3 240.4c-9.4 9.4-9.4 24.6 0 33.9l10.9 11c9.6 9.5 25.1 9.3 34.4-.4z"></path></svg>
             Buy</button>
          <button className="sell-button">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" focusable="false" class="chakra-icon custom-ysdqna" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3.4z"></path></svg>
             Sell</button>
        </div>
      </div>
  </div>
  );
};

export default TukiPage;
