import './home.css';
import { useEffect, useRef } from 'react';
import { ColorType, createChart } from 'lightweight-charts';
import { getData } from '../api/api';
import TukiPage from '../components/tukiPage';
import TxRecord from '../components/txRecord';
import { useMediaQuery } from 'react-responsive';

function Home() {
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        layout: {
          background: { color: '#222' },
          textColor: '#DDD',
        },
        grid: {
          vertLines: { color: '#444' },
          horzLines: { color: '#444' },
        },
        width: isMobile ? window.innerWidth : 1153.236539624924,
        height: isMobile ? window.innerHeight / 2 : 570,
      });

      const newSeries = chart.addCandlestickSeries({
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderVisible: false,
        wickUpColor: '#26a69a',
        wickDownColor: '#ef5350',
      });

      const handleData = async () => {
        const datas = await getData();
        const data = datas.data.map(({ open, high, low, close, timestamps }) => ({
          open: parseFloat(open),
          high: parseFloat(high),
          low: parseFloat(low),
          close: parseFloat(close),
          time: parseInt(timestamps),
        }));
        newSeries.setData(data);
      };

      handleData();

      return () => {
        chart.remove();
      };
    }
  }, [isMobile]);

  return (
    <div className="app-container">
      {isMobile ? (
        <>
          <TukiPage />
          {/* <div className="chart-and-tx-record">
            <div ref={chartContainerRef} className="chart-container"></div>
            <TxRecord />
          </div> */}
          {/* <button>

          </button> */}
        </>
      ) : (
        <div className="main-content" style={{ backgroundColor: "rgb(17,17,22)" }}>
          <div style={{ width: "10.9%" }}></div>
          <div className="chart-and-tx-record" >
            <div ref={chartContainerRef} className="chart-container"></div>
            <TxRecord />
          </div>
          <TukiPage />
        </div>
      )}
    </div>
  );
  //   return (
  //   <div className="app-container">

  //       <div className="main-content" style={{backgroundColor:"rgb(17,17,22)"}}>
  //         <div style={{ width: "10.9%" }}></div>
  //         <div className="chart-and-tx-record" >
  //           <div ref={chartContainerRef} className="chart-container"></div>
  //           <TxRecord />
  //         </div>
  //         <TukiPage />
  //       </div>
  //   </div>
  // );
}

export default Home;
