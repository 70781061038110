import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const PairsContext = createContext([]);

export const PairsProvider = ({ children }) => {
  const [pairs, setPairs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = ()=>{
      const tokenAddresses = "CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump";
      axios.get(`https://api.dexscreener.com/latest/dex/tokens/${tokenAddresses}`)
        .then((response) => {
          setPairs(response.data.pairs);
          console.log("---------------response.data.pairs--------------:",response.data.pairs);
          setLoading(false);
        })
        .catch((error) => {
          console.log("---------------error--------------:",error);
          setError(error.message);
          setLoading(false);
        });

    }

    fetchData();

    const intervalId = setInterval(fetchData,200);
    return()=> clearInterval(intervalId);

        }, []);


  return (
    <PairsContext.Provider value={{ pairs, loading, error }}>
      {children}
    </PairsContext.Provider>
  );
};

export const usePairs = () => useContext(PairsContext);
