
import axios from 'axios';

export const getData = async () => {
    try {
        const response = await axios.get(`https://kline.npmcow.com/get/kline/solana/main?interval=15m`);
        return response.data;
    } catch (error) {
        console.error('Error saving signature:', error);
        throw error;
    }
};

