import React, { useState } from 'react';
import './txRecord.css';
import { createFromIconfontCN, FontSizeOutlined } from '@ant-design/icons';
import useWebSocket from '../api/swapApi';




const TxRecord = ()=>{

    const MyIcon = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/c/font_4737065_misz5x2lwte.js', // 在 iconfont.cn 上生成
      });
    const {data} = useWebSocket();
    const [maker,setMaker] = useState(false)
    const [date, steDate] = useState(false)

    const dateOnClic = ()=>{
        steDate(!date)
    }
    
    // const makerOnClic = (sender)=>{
    //     return(
    //         // <a href={`https://solscan.io/tx/${sender}`}></a>
    //     )
    // }

    const formatTime = (timestamp) => {
        const currentTime = Date.now() / 1000; 
        const diff = currentTime - timestamp+2; 
    
        if (diff < 60) {
          return `${Math.floor(diff)}s ago`;
        } else if (diff < 3600) {
          const minutes = Math.floor(diff / 60);
          const seconds = Math.floor(diff % 60);
          return `${minutes}m ${seconds}s ago`; 
        } else if (diff < 86400) {
          const hours = Math.floor(diff / 3600);
          const minutes = Math.floor((diff % 3600) / 60); 
          const seconds = Math.floor(diff % 60); 
          return `${hours}h ${minutes}m ${seconds}s ago`; 
        } else {
          const days = Math.floor(diff / 86400);
          return `${days}d ago`;
        }
      };

    const type = (direct)=>{
        if(direct === "sell"){
            return false;
        }
        return true;
    }
      
    return (

        <div className="table-container">
                    
            <table>
                <thead className=''>
                    <tr>
                        <th>Date  </th>
                        <th>Type  </th>
                        <th>USD  </th>
                        <th>GOAT  </th>
                        <th>SOL  </th>
                        <th>Price  </th>
                        <th>Maker  </th>
                        <th>Txn</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((txn) => (
                        
                        <tr key={txn.id} style={type(txn.direct)?{ color:"rgb(61, 162, 112)"}:{color:"rgb(245, 101, 101)"}}>
                            <td onClick={dateOnClic}>{date ? txn.timestamp : formatTime(txn.timestamp)}</td>
                            <td>{txn.direct.charAt(0).toUpperCase() + txn.direct.slice(1)}</td>
                            <td>${Number(parseFloat(type(txn.direct)? txn.amount0Out*txn.price:txn.amount0In*txn.price).toFixed(2)).toLocaleString()}</td>
                            {/* <td>${parseFloat(txn.amount0In*txn.price).toFixed(2)}</td> */}
                            <td>{Number(parseFloat(type(txn.direct)? txn.amount0Out:txn.amount0In).toFixed(2)).toLocaleString()}</td>
                            <td>{Number(parseFloat(type(txn.direct)? txn.amount1In:txn.amount1Out).toFixed(2)).toLocaleString()}</td>
                            <td>${parseFloat(txn.price).toFixed(4)}</td>
                            <td><a href={`https://solscan.io/account/${txn.sender}`} target="_blank" rel="noopener noreferrer" style={{ color:"rgb(164, 207, 94)", }} className="fixed-width">{txn.sender.slice(0, 6)}</a> </td>
                            <td>
                                <a href={`https://solscan.io/tx/${txn.hash}`} target="_blank" rel="noopener noreferrer">
                                    
                                    <MyIcon type='icon-fenxiang' className='date'/></a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        
        
    );
}

export default TxRecord;